import { BsArrowRightShort } from 'react-icons/bs';
import { useState } from 'react';

export default function Page() {

  const [width, setWidth] = useState(window.innerWidth);
  
  window.addEventListener('resize', () => {
    setWidth(window.innerWidth);
  });

  return width > 425 ? (
    <div className="antialiased	text-white w-full h-full">
      
      <div className="absolute z-[2] flex items-center justify-center flex-col w-full h-full">
        <h1 className="text-3xl font-bold mb-2">Blindcord</h1>
        <h2 className="text-md font-semibold w-[400px] tracking-wide leading-6 text-center">Kullanıcılar arası anonim sohbeti sağlayan Discord sunucusudur.</h2>

        <div className="flex-row flex items-center justify-center gap-x-4 w-full h-max mt-4">
          <div onClick={() => window.location = 'https://discord.com/users/286370509711736832'} className="backdrop-blur-sm select-none flex items-center justify-center flex-row gap-x-2 bg-black/40 px-4 py-4 rounded-3xl cursor-pointer hover:ring ring-primary ring-offset-transparent transition-all">
            <img className="w-[32px] h-auto rounded-full" src="https://cdn.discordapp.com/avatars/286370509711736832/8f419881628124ab42c4c4856f169b6d.png?size=128"></img>
            <div className="flex justify-center text-sm flex-col gap-y-1">
              <a className="font-bold">nowongame</a>
              <a className="font-medium text-xs">Yönetici</a>
            </div>
          </div>

          <div onClick={() => window.location = 'https://discord.com/users/949705281863032872'} className="backdrop-blur-sm select-none flex items-center justify-center flex-row gap-x-2 bg-black/40 px-4 py-4 rounded-3xl cursor-pointer hover:ring ring-primary ring-offset-transparent transition-all">
            <img className="w-[32px] h-auto rounded-full" src="https://cdn.discordapp.com/avatars/949705281863032872/b22a42b78617d20aa526e5afd7a84c95.png?size=128"></img>
            <div className="flex justify-center text-sm flex-col gap-y-1">
              <a className="font-bold">skyhan</a>
              <a className="font-medium text-xs">Yönetici, Geliştirici</a>
            </div>
          </div>
        </div>

        <button onClick={() => window.location = 'https://discord.com/invite/blindcord'} className="select-none font-semibold text-md flex items-center justify-center flex-row bg-black/40 px-2 pr-4 py-2 rounded-3xl cursor-pointer hover:ring ring-primary ring-offset-transparent transition-all mt-4">
          <BsArrowRightShort size={24} className="-rotate-45"/>
          <a>Katıl</a>
        </button>
      </div>

      <div className="w-full h-full bg-black">
        <img className="w-full h-full absolute object-cover z-[1] opacity-[0.052]" src="https://cdn.discordapp.com/banners/1025757094583996436/c6350a0665d79c088b9afd14da024a1a.png?size=4096"/>
      </div>

    </div>
  ) : (
    <></>
  )
};